





















































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { ITransactionData } from '@/store/modules/history/types'
import { BN } from '@dcomm-tech/dcomm-js'
import { bnToBig } from '@/helpers/helper'
import { UnixNow } from '@dcomm-tech/dcomm-js/dist/utils'
import { ValidatorRaw } from '@/components/misc/ValidatorList/types'
import { WalletType } from '@/js/wallets/types'
import { getPriceAtUnixTime } from '@/helpers/price_helper'
import Big from 'big.js'

@Component
export default class StakingTx extends Vue {
    @Prop() transaction!: ITransactionData
    isStarted = false
    mounted() {
        this.updateStartStatus()
    }

    updateStartStatus() {
        let now = UnixNow()
        this.isStarted = now.toNumber() > this.startTime

        if (!this.isStarted) {
            setTimeout(() => {
                this.updateStartStatus()
            }, 5000)
        }
    }

    get isValidator() {
        return this.transaction.type === 'add_validator'
    }

    get isDelegatorReward() {
        if (this.isValidator) return false

        // If its a delegation, and the wallet does not own any of the inputs
        let ins = this.transaction.inputs || []
        let inUtxos = ins.map((input) => input.output)

        let inAddrs = []
        for (var i = 0; i < inUtxos.length; i++) {
            let utxo = inUtxos[i]
            inAddrs.push(...utxo.addresses)
        }

        let inWalletAddrs = inAddrs.filter((addr) => {
            return this.pAddrsClean.includes(addr)
        })

        return inWalletAddrs.length === 0
    }

    get actionText() {
        if (this.isValidator) {
            return 'Add Validator'
        } else {
            return 'Add Delegator'
        }
    }

    get stakeAmt(): BN {
        let tot = this.transaction.outputs.reduce((acc, out) => {
            if (out.stake) {
                return acc.add(new BN(out.amount))
            }
            return acc
        }, new BN(0))
        return tot
    }

    get wallet(): WalletType {
        return this.$store.state.activeWallet
    }

    get pAddrsClean(): string[] {
        let pAddrs = this.wallet.getAllAddressesP()
        return pAddrs.map((addr) => addr.split('-')[1])
    }

    // Reward received after a successful staking transaction
    get rewardAmt(): BN {
        if (!this.isRewarded) return new BN(0)

        let pAddrsClean = this.pAddrsClean

        let myRewardUTXOs = this.transaction.outputs.filter((utxo) => {
            let isReward = utxo.rewardUtxo
            let myReward = pAddrsClean.includes(utxo.addresses[0])
            return isReward && myReward
        })

        let tot = myRewardUTXOs.reduce((acc, out) => {
            return acc.add(new BN(out.amount))
        }, new BN(0))
        return tot
    }

    get rewardAmtBig(): Big {
        return bnToBig(this.rewardAmt, 9)
    }

    get rewardDateDcmPrice(): number | undefined {
        if (!this.endDate) return undefined
        let unixTime = this.endDate.getTime()
        let price = getPriceAtUnixTime(unixTime)
        return price
    }

    get totalRewardUSD(): Big | undefined {
        if (!this.rewardDateDcmPrice) return undefined
        return this.rewardAmtBig.times(this.rewardDateDcmPrice)
    }

    get rewardAmtText() {
        return bnToBig(this.rewardAmt, 9)
    }

    get amtText() {
        let big = bnToBig(this.stakeAmt, 9)
        return big.toLocaleString()
    }

    get isRewarded() {
        return this.transaction.rewarded
    }

    // DO NOT use this as the date reward received. Use validator end time instead.
    get rewardTime() {
        return this.transaction.rewardedTime
    }

    get startTime() {
        return this.transaction.validatorStart
    }
    get endtime() {
        return this.transaction.validatorEnd
    }

    get startDate() {
        return new Date(this.startTime * 1000)
    }

    get endDate() {
        return new Date(this.endtime * 1000)
    }
    get validator(): ValidatorRaw | null {
        let nodeId = this.transaction.validatorNodeID
        if (nodeId) {
            for (var i = 0; i < this.validators.length; i++) {
                let v = this.validators[i]
                let nodeIdRaw = v.nodeID.split('-')[1]
                if (nodeIdRaw === nodeId) {
                    return v
                }
            }
        }
        return null
    }

    get potentialReward() {
        let v = this.validator
        if (v) {
            if (this.isValidator) {
                return v.potentialReward
            } else {
                let delegators = v.delegators
                if (!delegators) return null
                for (var i = 0; i < delegators.length; i++) {
                    let d = delegators[i]
                    if (d.txID === this.transaction.id) {
                        return d.potentialReward
                    }
                }
            }
        }
        return null
    }

    get rewardBig() {
        let reward = this.potentialReward
        if (!reward) return null

        let bn = new BN(reward)
        return bnToBig(bn, 9)
    }

    get rewardText() {
        if (!this.rewardBig) return '?'
        return this.rewardBig.toLocaleString()
    }

    get validators(): ValidatorRaw[] {
        return this.$store.state.Authority.validators
    }

    get timeBarPerc() {
        if (!this.isStarted) return 0
        let now = UnixNow()
        // if (this.endtime) {
        let dur = this.endtime - this.startTime
        return ((now.toNumber() - this.startTime) / dur) * 100
        // }
    }
}
