







































import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'

import Spinner from '@/components/misc/Spinner.vue'
import TxHistoryRow from '@/components/SidePanels/TxHistoryRow.vue'
import HistoryPanelSelect from '@/components/SidePanels/HistoryPanelSelect.vue'
import EvmTxHistoryRow from '@/components/SidePanels/EvmTxHistoryRow.vue'
import { EvmTransaction, ITransactionData } from '@/store/modules/history/types'
import { DcommNetwork } from '@/js/DcommNetwork'

@Component({
    components: {
        TxHistoryRow,
        EvmTxHistoryRow,
        HistoryPanelSelect,
        Spinner,
    },
})
export default class TransactionHistoryPanel extends Vue {
    showTx: string = 'all'
    get isExplorer(): boolean {
        let network: DcommNetwork | null = this.$store.state.Network.selectedNetwork
        if (!network) return false
        if (network.explorerUrl) {
            return true
        }
        return false
    }

    get isEmpty(): boolean {
        if (this.transactions.length === 0) {
            return true
        }
        return false
    }
    get isUpdating(): boolean {
        return this.$store.state.History.isUpdating
    }
    get transactions(): ITransactionData[] {
        let res: ITransactionData[] = this.$store.state.History.transactions

        if (!res) return []

        let seenId: string[] = []
        let r: ITransactionData[] = res.filter((tx) => {
            if (seenId.includes(tx.id)) {
                return false
            }
            seenId.push(tx.id)
            return true
        })
        // A simple filter to ignore duplicate transactions (can happen if you send to self)
        return r
    }

    get cTransactions(): EvmTransaction[] {
        let res = this.$store.state.History.cTransactions
        // A simple filter to ignore duplicate transactions (can happen if you send to self)
        let seenId: string[] = []
        console.log(res)
        let r: EvmTransaction[] = res.filter((tx: EvmTransaction) => {
            if (seenId.includes(tx.hash)) {
                return false
            }
            seenId.push(tx.hash)
            return true
        })
        console.log(r, 'Filtered result')
        return r
    }
    get isActivityPage() {
        if (this.$route.fullPath.includes('/activity')) {
            return true
        }
        return false
    }

    get explorerUrl(): string {
        let addr = this.$store.state.address.split('-')[1]
        let currentNet = this.$store.state.Network.selectedNetwork.name.toLowerCase()
        return `https://explorer.dcomm.community/address/${addr}/network=${currentNet}`
    }
}
