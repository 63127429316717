














import { Vue, Component, Prop } from 'vue-property-decorator'
import { BaseTxAssetSummary, BaseTxNFTSummary } from '@/helpers/history_helper'
import DcommAsset from '@/js/DcommAsset'
import { bnToBig } from '@/helpers/helper'
import { BN } from '@dcomm-tech/dcomm-js'
import { UTXO } from '@/store/modules/history/types'
import TxHistoryNftFamilyGroup from '@/components/SidePanels/TxHistoryNftFamilyGroup.vue'

interface GroupDict {
    [key: number]: UTXO[]
}

@Component({
    components: { TxHistoryNftFamilyGroup },
})
export default class BaseTxNFTOutput extends Vue {
    @Prop() assetID!: string
    @Prop() summary!: UTXO[]

    get assetDetail(): DcommAsset {
        return this.$store.state.Assets.nftFamsDict[this.assetID]
    }

    groupDict: GroupDict = {}
    created() {
        let groupDict: GroupDict = {}
        this.summary.forEach((utxo) => {
            let groupID = utxo.groupID

            if (groupDict[groupID]) {
                groupDict[groupID].push(utxo)
            } else {
                groupDict[groupID] = [utxo]
            }
        })
        this.groupDict = groupDict
    }

    get groups(): number[] {
        let gNums: number[] = []

        this.summary.forEach((utxo) => {
            let groupID = utxo.groupID

            if (!gNums.includes(groupID)) {
                gNums.push(groupID)
            }
        })
        return gNums
    }
}
