<template>
    <div class="main_panel">
        <ConfirmLogout ref="logout"></ConfirmLogout>
        <div class="panel_nav">
            <!-- <button @click="logout" class="logout">
                {{ $t('logout.button') }}
            </button> -->
            <div class="column">
                <DayNightToggle class="hover_but"></DayNightToggle>
                <AccountMenu class="profile"></AccountMenu>
            </div>
            <network-menu class="net_menu"></network-menu>
        </div>
        <transition name="fade" mode="out-in">
            <transaction-history-panel class="panel_content"></transaction-history-panel>
        </transition>
    </div>
</template>
<script>
import NetworkMenu from '../NetworkSettings/NetworkMenu'
import TransactionHistoryPanel from './TransactionHistoryPanel'
import DayNightToggle from '@/components/misc/DayNightToggle'
import ConfirmLogout from '@/components/modals/ConfirmLogout.vue'
import AccountMenu from '@/components/wallet/sidebar/AccountMenu'

export default {
    components: {
        NetworkMenu,
        TransactionHistoryPanel,
        DayNightToggle,
        ConfirmLogout,
        AccountMenu,
    },
    methods: {
        logout() {
            // this.$store.dispatch('logout');
            // @ts-ignore
            this.$refs.logout.open()
        },
    },
}
</script>
<style scoped lang="scss">
@use '../../main';

.main_panel {
    display: grid;
    grid-template-rows: 1fr 360px;
    grid-gap: 15px;
    padding: 15px 10px 5px 0px;
}

.main_panel {
    display: grid;
    grid-template-rows: max-content 1fr;
    row-gap: 15px;
}
.profile {
    width: 100%;
    border: 1px solid #7a66f4;
    border-radius: 8px;
    padding: 4px 10px;
}
.panel_nav {
    //background: rgba(15, 1, 70, 0.5); /*display: flex;*/
    /*align-items: center;*/
    /*flex-direction: row;*/
    // display: grid;
    z-index: 2;
    // grid-template-columns: 3fr 1fr;
    /*justify-content: space-between;*/
    padding: 10px 16px;
    font-size: 14px;
    background-color: var(--bg-wallet);
    border-radius: 8px;

    > * {
        outline: none !important;
        padding: 4px 8px;
        border-radius: 4px;
    }
}

.net_menu {
    align-self: center;
}
.hover_but {
    transition-duration: 0.2s;
    cursor: pointer;
    margin: 0 auto;
    border: 1px solid #7a66f4;
    border-radius: 8px;
    padding: 10px;
    &:hover {
        box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.3);
    }
}

.panel_content {
    overflow: hidden;
    height: 100%;
    color: var(--font) !important;
    background-color: var(--bg-wallet);
    backdrop-filter: blur(50px);
    border-radius: 8px;
}

.logout {
    margin-left: auto;
    color: var(--font);
}

.column {
    display: grid;
    grid-template-columns: min-content 1fr;
    gap: 15px;
}
@include main.medium-device {
    .panel_nav {
        padding: 12px 16px;
    }
}
</style>
