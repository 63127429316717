












import { Vue, Component, Prop } from 'vue-property-decorator'
import { ITransactionData } from '@/store/modules/history/types'
import { dvm, athChain } from '@/DCOMM'
import { BN } from '@dcomm-tech/dcomm-js'
import { bnToBig } from '@/helpers/helper'

@Component
export default class ImportExport extends Vue {
    @Prop() transaction!: ITransactionData

    get isExport() {
        return this.transaction.type === 'export' || this.transaction.type === 'athvm_export'
    }

    get fromChainId() {
        if (!this.transaction.inputs) return '?'
        return this.transaction.inputs[0].output.chainID
    }

    get destinationChainId() {
        let outs = this.transaction.outputs || []

        for (var i = 0; i < outs.length; i++) {
            let out = outs[i]
            let chainId = out.chainID
            if (chainId !== this.fromChainId) {
                return chainId
            }
        }
        return this.fromChainId
    }

    // get chainId() {
    //     if (!this.isExport) {
    //         return this.transaction.outputs[0].chainID
    //     } else {
    //         return this.transaction.outputs[0].chainID
    //     }
    // }

    get chainAlias() {
        let chainId
        if (this.isExport) {
            chainId = this.fromChainId
        } else {
            chainId = this.destinationChainId
        }

        if (chainId === athChain.getBlockchainID()) {
            return 'ATH'
        } else if (chainId === dvm.getBlockchainID()) {
            return 'AST'
        }
        return chainId
    }

    get amt(): BN {
        if (this.isExport) {
            let outs = []
            let allOuts = this.transaction.outputs

            for (var i = 0; i < allOuts.length; i++) {
                let out = allOuts[i]
                let chainId = out.chainID

                if (chainId === this.destinationChainId) {
                    outs.push(out)
                }
            }

            let sumAmt = outs.reduce((acc, val) => {
                let amt = new BN(val.amount)
                return acc.add(amt)
            }, new BN(0))
            return sumAmt
        } else {
            let ins = this.transaction.inputs || []
            let sumAmt = ins.reduce((acc, val) => {
                let amt = new BN(val.output.amount)
                return acc.add(amt)
            }, new BN(0))

            return sumAmt
        }
        return new BN(0)
    }

    get txFee() {
        return new BN(this.transaction.txFee)
    }

    get amtText() {
        let total = this.amt.add(this.txFee)

        if (!this.isExport) {
            total = this.amt.sub(this.txFee)
        }

        let big = bnToBig(total, 9)
        return big.toLocaleString()
    }
}
