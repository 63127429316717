








import { Vue, Component, Prop } from 'vue-property-decorator'
import { NFTTransferOutput } from '@dcomm-tech/dcomm-js/dist/apis/dvm'
import NftPayloadView from '@/components/misc/NftPayloadView/NftPayloadView.vue'
import { PayloadBase } from '@dcomm-tech/dcomm-js/dist/utils'
import { Buffer } from '@dcomm-tech/dcomm-js'
import { PayloadTypes } from '@dcomm-tech/dcomm-js/dist/utils'
import { NftGroupDict } from '../wallet/portfolio/types'
import { DcommNftFamily } from '../../js/DcommNftFamily'
import { UTXO } from '@/store/modules/history/types'

let payloadtypes = PayloadTypes.getInstance()

@Component({
    components: { NftPayloadView },
})
export default class TxHistoryNftFamilyGroup extends Vue {
    // @Prop() payloads!: PayloadBase[]
    @Prop() utxos!: UTXO[]
    @Prop() assetID!: string

    created() {
        if (!this.nftFamsDict[this.assetID]) {
            this.$store.dispatch('Assets/addUnknownNftFamily', this.assetID)
        }
    }

    get nftFamsDict() {
        return this.$store.state.Assets.nftFamsDict
    }

    get quantity() {
        return this.utxos.length
    }

    parsePayload(rawPayload: string): PayloadBase {
        let payload = Buffer.from(rawPayload, 'base64')
        payload = Buffer.concat([new Buffer(4).fill(payload.length), payload])

        // try {
        let typeId = payloadtypes.getTypeID(payload)
        let pl: Buffer = payloadtypes.getContent(payload)
        let payloadbase: PayloadBase = payloadtypes.select(typeId, pl)
        return payloadbase
        // } catch (e) {
        //     console.error('Unable to parse payload.')
        // console.error(e)
        // }
    }

    get payload(): PayloadBase | null {
        let payload = this.utxos[0].payload
        if (!payload) return null

        try {
            let parsed = this.parsePayload(payload)
            return parsed
        } catch (e) {
            console.error('Unable to parse payload.')
        }
        return null
    }
}
