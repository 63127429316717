































import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'

import moment from 'moment'
// import TxHistoryValue from '@/components/SidePanels/TxHistoryValue.vue'
import TxHistoryNftFamilyGroup from '@/components/SidePanels/TxHistoryNftFamilyGroup.vue'
import {
    ITransactionData,
    TransactionType,
    UTXO,
    EvmTransaction,
} from '@/store/modules/history/types'
import { DcommNetwork } from '@/js/DcommNetwork'
import { Buffer } from '@dcomm-tech/dcomm-js'
import ImportExport from '@/components/SidePanels/History/ViewTypes/ImportExport.vue'
import BaseTx from '@/components/SidePanels/History/ViewTypes/BaseTx.vue'
import StakingTx from '@/components/SidePanels/History/ViewTypes/StakingTx.vue'
import getMemoFromByteString from '@/services/history/utils'
import CopyText from '../misc/CopyText.vue'

@Component({
    components: {
        // TxHistoryValue,
        TxHistoryNftFamilyGroup,
        // TxHistoryValueFunctional,
        CopyText,
    },
})
export default class EvmTxHistoryRow extends Vue {
    @Prop() transaction!: EvmTransaction

    get explorerUrl(): string | null {
        let network: DcommNetwork = this.$store.state.Network.selectedNetwork
        if (network.explorerSiteUrl) {
            let currentNet = this.$store.state.Network.selectedNetwork.name.toLowerCase()
            return `${network.explorerSiteUrl}/evmtx/${this.transaction.hash}?network=${currentNet}`
        }
        return null
    }

    get time() {
        return moment(this.transaction.createdAt)
    }

    get timeText(): string {
        let now = Date.now()
        let diff = now - new Date(this.transaction.createdAt).getTime()

        let dayMs = 1000 * 60 * 60 * 24

        if (diff > dayMs) {
            return this.time.format('MMM DD, YYYY')
        }
        return this.time.fromNow()
    }

    // get viewComponent() {
    //     let type = this.transaction.type

    //     switch (type) {
    //         case 'export':
    //         case 'import':
    //         case 'athvm_export':
    //         case 'athvm_import':
    //             return ImportExport
    //         case 'add_delegator':
    //         case 'add_validator':
    //             return StakingTx
    //         default:
    //             return BaseTx
    //     }
    // }
    shortenId(id: string, pad: number) {
        return `${id.substring(0, pad)}...${id.substring(id.length - pad, id.length)}`
    }
}
